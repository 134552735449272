const Noodl = require("@noodl/noodl-sdk");
const pdfMake = require("pdfmake/build/pdfmake");
const pdfFonts = require("pdfmake/build/vfs_fonts");
const htmlToPdfmake = require("html-to-pdfmake");

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const htmlToPdfNode = Noodl.defineNode({
	category: "My Utils",
	name: "htmlToPdfNode",
	inputs: {
		backgroundColor: "color",
		marginBottom: "number",
		htmlContent: "string",
		pdfPadding: "number",
		defaultName: "string",
		buttonText: "string",
	},
	outputs: {
		onClick: "signal",
		onFinished: "signal",
	},
	signals: {
		convertToPdf: function () {
			const div = document.createElement("div");
			div.style.fontFamily = "Roboto, sans-serif"; // Ensure the font is Roboto
			div.style.position = "absolute";
			div.style.left = "-10000px";
			div.style.top = "-10000px";
			div.style.width = "595px"; // Width of an A4 sheet in pixels at 72dpi
			div.innerHTML = this.inputs.htmlContent;
			document.body.appendChild(div);

			// Replace Quill classes with inline styles
			Array.from(div.getElementsByClassName("ql-align-center")).forEach(
				(el) => {
					el.style.textAlign = "center";
				}
			);
			Array.from(div.getElementsByClassName("ql-align-justify")).forEach(
				(el) => {
					el.style.textAlign = "justify";
				}
			);
			Array.from(div.getElementsByClassName("ql-align-right")).forEach(
				(el) => {
					el.style.textAlign = "right";
				}
			);
			Array.from(div.getElementsByClassName("ql-indent-1")).forEach(
				(el) => {
					el.style.textIndent = "40px"; // Adjust as needed
				}
			);
			Array.from(div.getElementsByClassName("ql-indent-2")).forEach(
				(el) => {
					el.style.textIndent = "80px"; // Adjust as needed
				}
			);
			Array.from(div.getElementsByClassName("ql-indent-3")).forEach(
				(el) => {
					el.style.textIndent = "120px"; // Adjust as needed
				}
			);
			Array.from(div.getElementsByClassName("ql-indent-4")).forEach(
				(el) => {
					el.style.textIndent = "160px"; // Adjust as needed
				}
			);
			Array.from(div.getElementsByClassName("ql-indent-5")).forEach(
				(el) => {
					el.style.textIndent = "200px"; // Adjust as needed
				}
			);
			Array.from(div.getElementsByClassName("ql-indent-6")).forEach(
				(el) => {
					el.style.textIndent = "240px"; // Adjust as needed
				}
			);
			Array.from(div.getElementsByClassName("ql-indent-7")).forEach(
				(el) => {
					el.style.textIndent = "280px"; // Adjust as needed
				}
			);
			Array.from(div.getElementsByClassName("ql-indent-8")).forEach(
				(el) => {
					el.style.textIndent = "320px"; // Adjust as needed
				}
			);

			const pdfContent = htmlToPdfmake(div.innerHTML);
			const pdfDoc = pdfMake.createPdf({ content: pdfContent });
			pdfDoc.download(this.inputs.defaultName + ".pdf");

			this.sendSignal("onClick");
			this.sendSignal("onFinished"); // Emit the onFinished signal
			document.body.removeChild(div);
		},
	},
});

Noodl.defineModule({
	nodes: [htmlToPdfNode],
	setup() {
		console.log("Module setup");
	},
});
